import { clsx } from 'clsx';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

import style from './style.module.scss';

enum ETabIndex {
  INACCESSIBLE = -1,
  ACCESSIBLE = 0,
}

interface ISidebarItemProps extends ICustomizable {
  children: React.ReactNode;
  path: LinkProps['href'];
  isDisabled?: boolean;
  onPress?: () => void;
}

export const SidebarItem = (props: ISidebarItemProps) => {
  const router = useRouter();

  return (
    <li className={clsx(style['sidebar-item'], props.className)} data-disabled={props.isDisabled}>
      <Link href={props.path}>
        <a
          className={clsx(style['sidebar-item__link'], props.className)}
          data-active={router.pathname.startsWith(props.path.toString()) && props.path !== '/'}
          data-disabled={props.isDisabled}
          aria-disabled={props.isDisabled}
          tabIndex={ETabIndex.ACCESSIBLE}
          target={props.path.toString().startsWith('/') ? '_self' : '_blank'}
          title={props.path.toString()}
          onClick={props.onPress}
        >
          {props.children}
        </a>
      </Link>
    </li>
  );
};
